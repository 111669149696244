import React from 'react'
import './App.css';
import { BrowserRouter as Router, Routes, Route, useParams, unstable_HistoryRouter, Navigate, } from "react-router-dom";
import HomeLayout from './Layouts/HomeLayout';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import ContactUs from './Pages/ContactUs/ContactUs';
import TermsandCondition from './Pages/TermsandCondition/TermsandCondition';
import Brochure from './Pages/Brochure/Brochure';
import Products from './Pages/Products/Products';
import ProductDetail from './Pages/DetailPage/ProductDetail';


function App() {
  return (
    <>
      <div>
        <Router>
          <Routes>
            <Route path='/' element={<HomeLayout Page={<Home />} />} />
            <Route path='/about' element={<HomeLayout Page={<About />} />} />
            <Route path='/privacypolicy' element={<HomeLayout Page={<PrivacyPolicy />} />} />
            <Route path='/product/:product_data' element={<HomeLayout Page={<ProductDetail />} />} />
            <Route path='/products' element={<HomeLayout Page={<Products />} />} />
            <Route path='/products/:category_id' element={<HomeLayout Page={<Products />} />} />
            <Route path='/contact' element={<HomeLayout Page={<ContactUs />} />} />
            <Route path='/brochure' element={<Brochure />} />
            <Route path='/termsandcondition' element={<HomeLayout Page={<TermsandCondition />} />} />
          </Routes>
        </Router>
      </div>
    </>
  )
}

export default App