import React, { useEffect, useState } from 'react'
import './ProductDetail.css';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom'
import { getProductList, getInquiry } from '../../API';
import { CiImageOn, CiShoppingTag } from 'react-icons/ci';
import CloseIcon from '@mui/icons-material/Close';
import { FiInfo } from "react-icons/fi";
import { Row, Col, Form, Button } from 'react-bootstrap';

function ProductDetail() {
    // Get Product Detail
    const { product_data } = useParams();
    const separateLastWord = (inputString) => {
        const words = inputString.split('-');
        const product_finish = words.pop();
        const product_name = words.join(' ');
        return { product_name, product_finish };
    };

    const [product, setProduct] = useState({});
    const fetchProductData = () => {
        axios.post(`${getProductList}?product_name=${separateLastWord(product_data).product_name}`)
            .then((res) => {
                setProduct(res.data.data.data[0]);
                setUserDetails({
                    ...userDetails,
                    product_id: res.data.data.data[0].id,
                });

            })
    }
    useEffect(() => {
        fetchProductData();
    }, [product_data])

    // Download image
    const handleDownloadClick = (e) => {
        e.preventDefault();

        const imageUrl = `https://www.thelaminc.com/admin/public/storage/${product.product_image}`;
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${product_data}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
    };
    // Get Related Product list
    const [relatedProduct, setrelatedProduct] = useState([]);

    const fetchRelatedProduct = () => {
        axios.post(`${getProductList}?colour_id=${product.colour && product.colour[0].id}&limit=5`)
            .then((res) => {
                res.data.data.data.map((ele) => {
                    const convertedString = ele.product_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('-');
                    ele.slug = `${convertedString}-${ele.finish[0].finish_name}`
                })
                const filteredProducts = res.data.data.data.filter((ele) => ele.product_name !== product.product_name);
                setrelatedProduct(filteredProducts);
            })
    }
    useEffect(() => {
        fetchRelatedProduct();
    }, [product])

    // inquiry popup
    const [showPopup, setShowPopup] = useState(false);
    const [userDetails, setUserDetails] = useState({
        product_id: '',
        username: '',
        contact_no: '',
        subject: '',
        message: '',
        user_type: '',
        company_name: '',
        email: '',
        user_type: ''
    });
    const [errors, setErrors] = useState({
        username: '',
        company_name: '',
        email: '',
        contact_no: '',
        subject: '',
        user_type: '',
        message: '',
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };

        if (!userDetails.username.trim()) {
            newErrors.username = 'Name is required';
            isValid = false;
        }

        // Add more validation checks for other fields...
        if (!userDetails.company_name.trim()) {
            newErrors.company_name = 'Company Name is required';
            isValid = false;
        }

        if (!userDetails.email.trim()) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
            newErrors.email = 'Invalid email address';
            isValid = false;
        }

        if (!userDetails.contact_no.trim()) {
            newErrors.contact_no = 'Contact No is required';
            isValid = false;
        } else if (!/^[0-9]+$/.test(userDetails.contact_no)) {
            newErrors.contact_no = 'Contact No should contain only numeric characters';
            isValid = false;
        }

        if (!userDetails.subject.trim()) {
            newErrors.subject = 'Subject is required';
            isValid = false;
        }

        if (!userDetails.user_type.trim()) {
            newErrors.user_type = 'User Type is required';
            isValid = false;
        }

        if (!userDetails.message.trim()) {
            newErrors.message = 'Message is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {

            axios.post(`${getInquiry}`, userDetails)
                .then((response) => {
                    console.log('Form submitted successfully:', response.data);
                    setUserDetails({
                        product_id: '',
                        username: '',
                        contact_no: '',
                        subject: '',
                        message: '',
                        user_type: '',
                        company_name: '',
                        email: '',
                    });
                    setErrors({
                        username: '',
                        company_name: '',
                        email: '',
                        contact_no: '',
                        subject: '',
                        user_type: '',
                        message: '',
                    });
                    setShowPopup(false);
                })
                .catch((error) => {
                    console.error('Error submitting the form:', error);
                });
        } else {
            console.log('Form has validation errors. Please check the fields.');
        }
    };
    const handleInputChange = (fieldName, value) => {
        setUserDetails({
            ...userDetails,
            [fieldName]: value,
        });
        setErrors({
            ...errors,
            [fieldName]: '',
        });
    };


    return (
        <div className='product-detail-page section-gap'>
            <div className='container'>
                <div className='img-block'>
                    <img src={`https://www.thelaminc.com/admin/public/storage/${product.product_image}`} alt="" />
                </div>
                <div className='title-bar'>
                    <h2>{product.product_code} | {product.product_name}</h2>
                    <div className='buttons'>
                        <NavLink onClick={(e) => handleDownloadClick(e)} className='nav-link common-btn'>Download Image <CiImageOn /> </NavLink>
                        <NavLink onClick={() => setShowPopup(true)} className='nav-link common-btn'>Inquire Now <FiInfo /> </NavLink>

                    </div>
                    {showPopup && (
                        <div className="popup">
                            <div className="popup-content">
                                <span className="closepopup" onClick={() => setShowPopup(false)}>
                                    <CloseIcon />
                                </span>
                                <h3>INQUIRY</h3>
                                <form className="form-container">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridCompanyName" className="half-width">
                                            <Form.Control value={userDetails.username} onChange={(e) => handleInputChange('username', e.target.value)} type="text" placeholder=" Name*" />
                                            <Form.Text className="text-danger">{errors.username}</Form.Text>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCompanyName" className="half-width">
                                            <Form.Control value={userDetails.company_name} onChange={(e) => handleInputChange('company_name', e.target.value)} type="text" placeholder="Company Name*" />
                                            <Form.Text className="text-danger">{errors.company_name}</Form.Text>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail" className="half-width">
                                            <Form.Control value={userDetails.email} onChange={(e) => handleInputChange('email', e.target.value)} type="email" placeholder="Enter email*" />
                                            <Form.Text className="text-danger">{errors.email}</Form.Text>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridContactNo" className="half-width">
                                            <Form.Control value={userDetails.contact_no} onChange={(e) => handleInputChange('contact_no', e.target.value)} type="number"  pattern="[0-9]*"    placeholder="Contact No*" />
                                            <Form.Text className="text-danger">{errors.contact_no}</Form.Text>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridName" className="half-width">
                                            <Form.Control value={userDetails.subject} onChange={(e) => handleInputChange('subject', e.target.value)} type="text" placeholder="Subject*" />
                                            <Form.Text className="text-danger">{errors.subject}</Form.Text>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCompanyName" className="half-width">
                                            <Form.Control value={userDetails.user_type} onChange={(e) => handleInputChange('user_type', e.target.value)} type="text" placeholder="Who I am?*" />
                                            <Form.Text className="text-danger">{errors.user_type}</Form.Text>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridMessage" className="full-width">
                                            <Form.Control value={userDetails.message} onChange={(e) => handleInputChange('message', e.target.value)} as="textarea" placeholder="Message*" />
                                            <Form.Text className="text-danger">{errors.message}</Form.Text>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <button onClick={handleSubmit} className="inqbtn" type="submit">
                                            Submit
                                        </button>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    )}

                </div>
                <div className='detail-bar'>
                    <p>Shade | <span>{product.colour && product.colour[0].colour_name}</span></p>
                    <p>Type | <span>{product.type && product.type[0].type_name}</span></p>
                    <p>Finish | <span>{product.finish && product.finish[0].finish_name}</span></p>
                    <p>Size | <span>{product.product_size}</span></p>
                </div>
                <div className='buttons-bar'>
                    <NavLink onClick={(e) => handleDownloadClick(e)} className='nav-link common-btn'>Download Image <CiImageOn /> </NavLink>
                    <NavLink onClick={() => setShowPopup(true)} className='nav-link common-btn'>Inquire Now <FiInfo /> </NavLink>

                </div>


                <div className='related-product'>
                    <div className='Heading'>
                        <h2>Recommended For You</h2>
                    </div>
                    <div className='productList'>
                        {relatedProduct.map((val) => (
                            <NavLink className='product-card' to={`/product/${val.slug}`}>
                                <div className='product-img'>
                                    <img src={`https://www.thelaminc.com/admin/public/storage/${val.product_image}`} alt="" />
                                </div>
                                <div className="product-detail">
                                    <p>{val.product_code} | {val.finish[0]?.finish_name}</p>
                                    <h6>{val.product_name}</h6>
                                </div>
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className='button-block'>
                    <NavLink to={`/products/${product.category && product.category.id}`} className='nav-link common-btn'>View<span>{product.category && product.category.category_name}</span> {product.category && product.category.category_type} <CiShoppingTag /> </NavLink>
                    <NavLink to='/products' className='nav-link common-btn'>View All Products <CiShoppingTag /> </NavLink>
                </div>
            </div>

        </div>
    )
}

export default ProductDetail