import React from 'react'
import './ContactUs.css';
import emailicons from '../../Images/black-mail.webp'
import phoneicons from '../../Images/phoneicons.png'
import locationicons from '../../Images/locationicons.png'


function ContactUs() {
  return (
    <>

      <section class="contact-area contact-page-area">
        <div class="container">
          <div class="row contact-page-wrapper">
            <div className='bannersection'>
              <h2>Get In Touch</h2>
            </div>
            <div class="col-xl-9">
              <div class="contact-form-wrap aos-init aos-animate" data-aos="fade-right">
                <div class="contact-form">
                  <form id="contact-form" method="POST">
                    <div class="row row-gutter-20">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input class="form-control" type="text" name="con_name" placeholder="Name *" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input class="form-control" type="email" name="con_email" placeholder="Email *" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <input class="form-control" type="text" placeholder="Subject (Optinal)" />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb--0">
                          <textarea class="form-control" name="con_message" placeholder="Message"></textarea>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb--0">
                          <button class="btn-contact" type="submit">Send Message</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="contact-info-wrap">
                <div class="contact-info">
                  <div class="row">
                    <div class="col-lg-4 col-xl-12">
                      <div class="info-item aos-init aos-animate" data-aos="fade-left" data-aos-delay="60">
                        <div class="icon">
                          <img src={phoneicons} width="65" height="65" alt="Image-HasTech" />
                        </div>
                        <div class="info">
                          <p>
                            <a href="tel:+917926890952">+91 79 - 2689 0952</a>
                            <a href="tel:+917926890952">+91 79 - 2689 0952</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-12">
                      <div class="info-item aos-init aos-animate" data-aos="fade-left" data-aos-delay="120">
                        <div class="icon">
                          <img src={emailicons} width="65" height="65" alt="Image-HasTech" />
                        </div>
                        <div class="info">
                          <p>
                            <a href="mailto:connect@thelaminc.com">connect@thelaminc.com</a>
                            <a href="mailto:info@thelaminc.com">info@thelaminc.com</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-xl-12">
                      <div class="info-item aos-init aos-animate" data-aos="fade-left">
                        <div class="icon">
                          <img src={locationicons} width="69" height="65" alt="Image-HasTech" />
                        </div>
                        <div class="info">
                          <p>1, Sayona Estate, Opp. Gollops Motors Showroom, SG Highway, Ahmedabad, Gujarat, India, 382 210</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs