import React, { useEffect, useState } from 'react'
import './Header.css';
import { NavLink } from 'react-router-dom'
import Mainlogo from '../../Images/logo.png';
import { PiDownload } from "react-icons/pi";


const Header = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  return (
    <>
      <header>
        <div className='header'>
          <div className='container'>
            <div className='header-block'>
              <div className="logo">
                <NavLink to='/' onClick={() => setIsMobileMenuActive(false)}>
                  <img src={Mainlogo} alt="" />
                </NavLink>
              </div>

              <div className='mobile-nav'>
                <div className={isMobileMenuActive ? 'mobile-menu active' : 'mobile-menu'} onClick={() => setIsMobileMenuActive((prev) => !prev)}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              <div className="nav-bar">
                <ul className="nav-links">
                  <li className="nav-item">
                    <NavLink to="/products" className='nav-link'> Products </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about" className='nav-link'> About </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" className='nav-link'> Contact </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/brochure" className='nav-link common-btn'>Product Portfolio <PiDownload /> </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={isMobileMenuActive ? 'mobile-menubar active' : 'mobile-menubar'}>
          <ul className="nav-links">
            <li className="nav-item">
              <NavLink to="/products" onClick={() => setIsMobileMenuActive(false)} className='nav-link'> Products </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" onClick={() => setIsMobileMenuActive(false)} className='nav-link'> About </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" onClick={() => setIsMobileMenuActive(false)} className='nav-link'> Contact </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/" onClick={() => setIsMobileMenuActive(false)} className='nav-link common-btn'>Product Portfolio <PiDownload /> </NavLink>
            </li>
          </ul>
        </div>
      </header>

    </>
  )
}
export default Header