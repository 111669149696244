import React, { useEffect, useState } from 'react';
import './Products.css';
import filtericons from '../../Images/filter.svg';
import filterclear from '../../Images/clear.svg';
import downIcon from '../../Images/view.svg';
import { getCategoryList, getColorList, getFinishList, getProductList, getTypeList } from '../../API';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { Pagination } from '@mui/material';

function Products() {

    const { category_id } = useParams();
    const navigate = useNavigate();

    // Filter Data List
    const [filterData, setFilterData] = useState({
        collections: [],
        series: [],
        shades: [],
        size: [{ id: 1, size_name: '4x8', size_label: '4 X 8' }, { id: 2, size_name: '4x10', size_label: '4 X 10' }],
        finish: [],
        types: []
    });

    // Get All Filter Data
    useEffect(() => {
        axios.post(`${getCategoryList}?limit=1000`, { category_type: ['collection', 'series'] })
            .then((response) => {
                const collectionData = response.data.data.category?.data.filter(category => category.category_type === 'collection');
                const seriesData = response.data.data.category?.data.filter(category => category.category_type === 'series');

                setFilterData((prevFilterData) => ({
                    ...prevFilterData,
                    collections: collectionData,
                    series: seriesData
                }));
            });

        axios.get(getColorList)
            .then((res) => {
                setFilterData((prevFilterData) => ({
                    ...prevFilterData,
                    shades: res.data.data.colour
                }));
            });

        axios.get(getFinishList)
            .then((res) => {
                setFilterData((prevFilterData) => ({
                    ...prevFilterData,
                    finish: res.data.data.finish
                }));
            });

        axios.get(getTypeList)
            .then((res) => {
                setFilterData((prevFilterData) => ({
                    ...prevFilterData,
                    types: res.data.data.types
                }));
            });
    }, []);


    // Get Selected Filter
    const [filter, setFilter] = useState({
        collection: [],
        series: [],
        shades: [],
        size: [],
        finish: [],
        types: []
    });

    const handleSelection = (e, type, size) => {
        const { value } = e.target;
        const numericValue = parseInt(value, 10);

        setPageState((prevState) => ({
            ...prevState,
            page: 1
        }));

        setFilter((prevFilter) => {
            const updatedSelection = [...prevFilter[type]];

            if (type !== "size") {
                if (updatedSelection.includes(numericValue)) {
                    return {
                        ...prevFilter,
                        [type]: updatedSelection.filter((item) => item !== numericValue),
                    };
                } else {
                    updatedSelection.push(numericValue);
                    return {
                        ...prevFilter,
                        [type]: updatedSelection,
                    };
                }
            } else {
                if (updatedSelection.includes(size.size_name)) {
                    return {
                        ...prevFilter,
                        [type]: updatedSelection.filter((item) => item !== size.size_name),
                    };
                } else {
                    updatedSelection.push(size.size_name);
                    return {
                        ...prevFilter,
                        [type]: updatedSelection,
                    };
                }
            }
        });
    };

    useEffect(() => {
        const tempCollection = filterData.collections.find((ele) => ele.id === parseInt(category_id));
        const tempSeries = filterData.series.find((ele) => ele.id === parseInt(category_id));

        setFilter((prev) => {
            const isCollectionExist = prev.collection.some((item) => item.id === tempCollection?.id);
            const isSeriesExist = prev.series.some((item) => item.id === tempSeries?.id);

            return {
                ...prev,
                collection: tempCollection && !isCollectionExist ? [...prev.collection, tempCollection.id] : prev.collection,
                series: tempSeries && !isSeriesExist ? [...prev.series, tempSeries.id] : prev.series
            };
        });
    }, [category_id, filterData]);


    console.log(filter)

    // Pagination
    const [pageState, setPageState] = useState({
        total: 0,
        totalPages: 5,
        page: 1,
        pageSize: 12
    });

    const handlePageChange = (selectedPage) => {
        setPageState((prevState) => ({
            ...prevState,
            page: selectedPage
        }));
    };

    const ResultInfo = () => {
        const start = (pageState.page - 1) * pageState.pageSize + 1;
        const end = Math.min(start + pageState.pageSize - 1, pageState.total);

        return (
            <>
                <div className="result-info">
                    Showing <span>{start}-{end}</span> of <span>{pageState.total}</span> Products
                </div>

                <Pagination
                    count={pageState.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageState.page}
                    onChange={(e, page) => handlePageChange(page)}
                    total={pageState.totalPages}
                />
            </>
        );
    };

    // Search
    const [search, setSearch] = useState("")
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    // Clear filter
    const clearFilter = () => {
        navigate('/products')
        setFilter({
            collection: [],
            series: [],
            shades: [],
            size: [],
            finish: [],
            types: []
        })
    }

    // Get products List
    const [productsList, setProductsList] = useState([]);
    let apiUrl = `${getProductList}?page=${pageState.page}&limit=${pageState.pageSize}`
    if (search) { apiUrl += `&product_code=${search}`; }
    if (search) { apiUrl += `&product_name=${search}`; }
    if (filter.collection.length > 0) { apiUrl += `&category_id=${filter.collection}`; }
    if (filter.series.length > 0) { apiUrl += `&category_id=${filter.series}`; }
    if (filter.shades.length > 0) { apiUrl += `&colour_id=${filter.shades}`; }
    if (filter.size.length > 0) { apiUrl += `&product_size=${filter.size}`; }
    if (filter.finish.length > 0) { apiUrl += `&finish_id=${filter.finish}`; }
    if (filter.types.length > 0) { apiUrl += `&type_id=${filter.types}`; }

    const fetchProductsList = () => {
        axios.post(apiUrl)
            .then((res) => {
                res.data.data.data.map((ele) => {
                    const convertedString = ele.product_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('-');
                    ele.slug = `${convertedString}-${ele.finish[0].finish_name}`
                })
                setProductsList(res.data.data.data)
                setPageState({
                    ...pageState,
                    total: res.data.data.total,
                    totalPages: res.data.data.last_page,
                    page: res.data.data.current_page,
                })
            })
    }

    useEffect(() => {
        fetchProductsList();
    }, [filter, search, pageState.page, pageState.pageSize])


    // View More Filter button
    const [allCollection, setAllCollection] = useState(false);
    const [allSeries, setAllSeries] = useState(false);
    const [allFinish, setAllFinish] = useState(false);
    const [allTypes, setAllTypes] = useState(false);


    // Side Bar Mobile
    const [isActive, setIsActive] = useState(false);

    return (
        <div className='products-list'>
            <div className='container'>
                <div className='bannersection'>
                    <h2>Products</h2>
                </div>
                <div className='list-block'>
                    <div className={isActive ? 'sidebar mobile-avtive' : 'sidebar'}>
                        <div className="close" onClick={() => setIsActive((prev) => !prev)}>
                            <img src={filterclear} alt="" />
                        </div>
                        <div className='heading'>
                            <div className="text">
                                <img src={filtericons} alt="" />
                                <p>
                                    Filters
                                    {(filter.collection.length + filter.series.length + filter.finish.length + filter.size.length + filter.shades.length + filter.types.length) > 0 ? <span className='filter-count'></span> : ''}
                                </p>
                            </div>
                            {
                                filter.collection.length > 0 ||
                                    filter.series.length > 0 ||
                                    filter.finish.length > 0 ||
                                    filter.size.length > 0 ||
                                    filter.shades.length > 0 ||
                                    filter.types.length > 0 ?
                                    <div className="clearbtn" onClick={() => clearFilter()}>
                                        <img src={filterclear} alt="" />
                                        <p>Clear</p>
                                    </div> : ""
                            }
                        </div>

                        <div className='filter-item'>
                            <div className='title'>
                                <p>Collections</p>
                            </div>
                            <div className='checkbox'>
                                <Form.Group className={allCollection ? "checkbox-list all" : "checkbox-list"}>
                                    {
                                        filterData.collections.slice(0, allCollection ? filterData.collections.length : 5).map((ele) => (
                                            <Form.Check
                                                type="checkbox"
                                                id={`${ele.id}-${ele.category_name}`}
                                                key={`${ele.id}-${ele.category_name}`}
                                                name={ele.category_name}
                                                value={ele.id}
                                                checked={filter.collection.includes(ele.id)}
                                                label={ele.category_name}
                                                onChange={(e) => handleSelection(e, "collection")}
                                            />
                                        ))
                                    }
                                </Form.Group>
                                <div className='viewmore-btn'>
                                    {!allCollection ?
                                        <p onClick={() => setAllCollection(true)}>
                                            <img src={downIcon} />
                                            View More
                                        </p>
                                        :
                                        <p className='less' onClick={() => setAllCollection(false)}>
                                            <img src={downIcon} />
                                            View Less
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='filter-item'>
                            <div className='title'>
                                <p>Series</p>
                            </div>
                            <div className='checkbox'>
                                <Form.Group className={allSeries ? "checkbox-list all" : "checkbox-list"}>
                                    {
                                        filterData.series.slice(0, allSeries ? filterData.series.length : 5).map((ele) => (
                                            <Form.Check
                                                type="checkbox"
                                                id={`${ele.id}-${ele.category_name}`}
                                                key={`${ele.id}-${ele.category_name}`}
                                                name={ele.category_name}
                                                value={ele.id}
                                                checked={filter.series.includes(ele.id)}
                                                label={ele.category_name}
                                                onChange={(e) => handleSelection(e, "series")}
                                            />
                                        ))
                                    }
                                </Form.Group>
                                <div className='viewmore-btn'>
                                    {!allSeries ?
                                        <p onClick={() => setAllSeries(true)}>
                                            <img src={downIcon} />
                                            View More
                                        </p>
                                        :
                                        <p className='less' onClick={() => setAllSeries(false)}>
                                            <img src={downIcon} />
                                            View Less
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='filter-item'>
                            <div className='title'>
                                <p>Shades</p>
                            </div>
                            <div className='checkbox'>
                                <Form.Group className="checkbox-list">
                                    {
                                        filterData.shades.map((ele) => (
                                            <Form.Check
                                                type="checkbox"
                                                id={`${ele.id}-${ele.colour_name}`}
                                                key={`${ele.id}-${ele.colour_name}`}
                                                name={ele.colour_name}
                                                value={ele.id}
                                                checked={filter.shades.includes(ele.id)}
                                                label={ele.colour_name}
                                                onChange={(e) => handleSelection(e, "shades")}
                                            />
                                        ))
                                    }
                                </Form.Group>
                            </div>
                        </div>

                        <div className='filter-item'>
                            <div className='title'>
                                <p>Size</p>
                            </div>
                            <div className='checkbox'>
                                <Form.Group className="checkbox-list">
                                    {
                                        filterData.size.map((ele) => (
                                            <Form.Check
                                                type="checkbox"
                                                id={`${ele.id}-${ele.size_name}`}
                                                key={`${ele.id}-${ele.size_name}`}
                                                name={ele.size_name}
                                                value={ele.id}
                                                checked={filter.size.includes(ele.size_name)}
                                                label={ele.size_label}
                                                onChange={(e) => handleSelection(e, "size", ele)}
                                            />
                                        ))
                                    }
                                </Form.Group>
                            </div>
                        </div>

                        <div className='filter-item'>
                            <div className='title'>
                                <p>Finish</p>
                            </div>
                            <div className='checkbox'>
                                <Form.Group className={allFinish ? "checkbox-list all" : "checkbox-list"}>
                                    {
                                        filterData.finish.slice(0, allFinish ? filterData.finish.length : 5).map((ele) => (
                                            <Form.Check
                                                type="checkbox"
                                                id={`${ele.id}-${ele.finish_name}`}
                                                key={`${ele.id}-${ele.finish_name}`}
                                                name={ele.finish_name}
                                                value={ele.id}
                                                checked={filter.finish.includes(ele.id)}
                                                label={ele.finish_name}
                                                onChange={(e) => handleSelection(e, "finish")}
                                            />
                                        ))
                                    }
                                </Form.Group>
                                <div className='viewmore-btn'>
                                    {!allFinish ?
                                        <p onClick={() => setAllFinish(true)}>
                                            <img src={downIcon} />
                                            View More
                                        </p>
                                        :
                                        <p className='less' onClick={() => setAllFinish(false)}>
                                            <img src={downIcon} />
                                            View Less
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='filter-item'>
                            <div className='title'>
                                <p>Types</p>
                            </div>
                            <div className='checkbox'>
                                <Form.Group className={allTypes ? "checkbox-list all" : "checkbox-list"}>
                                    {
                                        filterData.types.slice(0, allTypes ? filterData.types.length : 5).map((ele) => (
                                            <Form.Check
                                                type="checkbox"
                                                id={`${ele.id}-${ele.type_name}`}
                                                key={`${ele.id}-${ele.type_name}`}
                                                name={ele.type_name}
                                                value={ele.id}
                                                checked={filter.types.includes(ele.id)}
                                                label={ele.type_name}
                                                onChange={(e) => handleSelection(e, "types")}
                                            />
                                        ))
                                    }
                                </Form.Group>
                                <div className='viewmore-btn'>
                                    {!allTypes ?
                                        <p onClick={() => setAllTypes(true)}>
                                            <img src={downIcon} />
                                            View More
                                        </p>
                                        :
                                        <p className='less' onClick={() => setAllTypes(false)}>
                                            <img src={downIcon} />
                                            View Less
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='product-list'>
                        <div className='product-top-bar'>
                            <div className="text" onClick={() => setIsActive((prev) => !prev)}>
                                <img src={filtericons} alt="" />
                                <p>
                                    Filters
                                    {(filter.collection.length + filter.series.length + filter.finish.length + filter.size.length + filter.shades.length + filter.types.length) > 0 ? <span className='filter-count'></span> : ''}
                                </p>
                            </div>
                            <div className="search-box">
                                <Form.Control type="text" placeholder="Search.." name="seach" className="search-input" onChange={(e) => handleSearch(e)} />
                                <CiSearch />
                            </div>
                        </div>
                        <div className='productList'>
                            {productsList.map((val) => (
                                <NavLink className='product-card' to={`/product/${val.slug}`}>
                                    <div className='product-img'>
                                        <img src={`https://www.thelaminc.com/admin/public/storage/${val.product_image}`} alt="" />
                                    </div>
                                    <div className="product-detail">
                                        <p>{val.product_code} | {val.finish[0]?.finish_name}</p>
                                        <h6>{val.product_name}</h6>
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                        <div className='result-block'>
                            <ResultInfo />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
