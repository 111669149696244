import React from 'react'
import './Brochure.css';

const Brochure = () => {
 return (
 <div className='broachure'>
 <iframe src="http://www.thelaminc.com/admin/storage/app/public/portfolio.pdf" height="100vh" width='100%' />
 </div>
 );
};
export default Brochure;